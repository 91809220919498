import ReplayIcon from '@mui/icons-material/Replay';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Image from 'next/image';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CRALogo from 'images/cra-logo.webp';
import { type Locale } from 'models/locale';
import { type RegionId } from 'models/report';
import { type ReportPeriod } from 'report/reportPeriod';

export const ReportDisclaimer = ({
    regionId,
    period,
    locale,
}: {
    regionId: RegionId;
    period: ReportPeriod;
    locale: Locale;
}): React.ReactElement => {
    switch (regionId) {
        case 'au':
            return (
                <>
                    {period.year === 2023 && period.month === 10 && (
                        <>
                            <p>
                                Note: Studio71 is temporarily excluded from the rankers this month due to data
                                collection issues.
                            </p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 9 && (
                        <>
                            <p>Note: Studio71 experienced data collection issues through a portion of the period</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 2 && (
                        <>
                            <p>
                                NOTE: The Kyle &amp; Jackie O Show (ARN’s iHeartPodcast Network Australia) experienced a
                                spike in the listener and download volume in February, due to additional consumption of
                                episodes focused on the ‘Married At First Sight’ TV show currently on-air in Australia.
                            </p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 1 && (
                        <>
                            <p>NOTE: DM Podcasts will be represented by LiSTNR (SCA) starting 1 February 2023.</p>
                        </>
                    )}
                    {period.year === 2022 && period.month === 11 && (
                        <>
                            <p>
                                Note: Samsung made an update to their Samsung Free app which caused a spike in
                                downloads. Samsung resolved the issue moving forward, starting in December. To mitigate
                                the issue, we have reprocessed our November data to remove those spikes.
                            </p>
                            <p>
                                Note: Sports Entertainment Network (SEN) experienced minor data collection issues
                                through a portion of the period.
                            </p>
                        </>
                    )}
                    {period.year === 2022 && period.month === 7 && (
                        <p>
                            *Morbid was not included in the June ranker as it was in transition from Audioboom to
                            Wondery.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 9 && (
                        <p>
                            Note: Shameless Media experienced minor data collection issues through a portion of the
                            period.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 10 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify. This adjustment process will continue into next month as our team diligently works
                            to remove the Chartable prefix.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 11 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify.
                        </p>
                    )}
                    <PoweredByTriton />
                    <p>
                        <strong>ABOUT THE AUSTRALIAN PODCAST RANKER</strong>
                    </p>
                    <p>
                        The Australian Podcast Ranker lists the Top 200 podcasts from participating publishers that are
                        consumed by listeners located in Australia. The All-Australian Top 200 Category Ranker lists
                        only the Top 200 podcasts commissioned by an Australian publisher, consumed by listeners located
                        in Australia.
                    </p>
                    <p>
                        The Australian Podcast Ranker is based on a calendar month reporting period. The entities listed
                        are ranked by Monthly Listeners in accordance with the latest IAB Podcast Technical Measurement
                        Guidelines. If you wish to sort by downloads, you can click on the “Downloads” column to re-sort
                        amongst the existing data.
                    </p>
                    <p>
                        For podcasts with multiple categories in the RSS feed, we display and filter by only the first
                        category.
                    </p>
                    <p>
                        The Top Sales Representatives ranker includes participating entities that have been delegated
                        advertising sales responsibilities by a publisher, such as a third-party sales house, sales
                        representation firm, or sales network. Listener and download numbers from multiple publishers
                        can be aggregated under one sales representative.
                    </p>
                    <p>
                        The Ranker is commissioned by{' '}
                        <a href="http://www.commercialradio.com.au/" target="_blank" rel="noreferrer">
                            Commercial Radio & Audio
                        </a>{' '}
                        and includes the participation of the following publishers and sales representation:{' '}
                        {australianPublishers
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((publisher, index) => (
                                <React.Fragment key={publisher.label}>
                                    <a href={publisher.url} target="_blank" rel="noreferrer">
                                        {publisher.label}
                                    </a>
                                    {index < australianPublishers.length - 1 && ', '}
                                </React.Fragment>
                            ))}
                        .
                    </p>
                    <p css={{ textAlign: 'center' }}>
                        <Image src={CRALogo.src} alt="Commercial Radio & Audio" width={200} height={124} />
                    </p>
                    <p>
                        <strong>METRIC DEFINITIONS</strong>
                    </p>
                    <ul>
                        <li>
                            Listeners - A ‘listener’ is a single user who downloads content for either immediate or
                            delayed consumption. Listeners are identified by a combination of IP address and user agent
                            in accordance with the IAB filtering rules described in the IAB Podcast Measurement
                            Technical Guidelines v2.2.
                        </li>
                        <li>
                            Downloads - A ‘download’ is a unique file request that was downloaded. Downloads include
                            complete file downloads, progressive downloads, as well as partial downloads in accordance
                            with the IAB Podcast Measurement Technical Guidelines v2.2 filtering rules (i.e. spiders and
                            bots, data centers, one-minute of content minimum download, etc.). This process filters
                            multiple requests from the same IP address, user agent, episode and date.
                        </li>
                        <li>
                            Publisher - An entity that represents a group of podcasts that it either commissions,
                            produces itself or acts as the primary representative for that content in Australia.
                        </li>
                        <li>
                            Rank
                            <ul>
                                <li>
                                    <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                    &quot;Debut&quot; indicates that the show is new to the ranker, making its debut for
                                    the first time in the last 13 months.
                                </li>
                                <li>
                                    <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> &quot;Return&quot;
                                    indicates that the show has returned to the Top 200 after having not appeared in the
                                    previous reporting period(s).
                                </li>
                            </ul>
                        </li>
                        <li>
                            Index (Monthly Podcast pop 18+) - Compares % of a podcast’s audience against the overall
                            Australian monthly podcast listener population (age 18+). Eg: Index 125 &quot;Genre X is 25%
                            more likely than average monthly podcast listener to do purchase a new car.&quot;
                        </li>
                    </ul>
                </>
            );

        case 'nz':
            return (
                <>
                    {period.year === 2023 && period.month === 10 && (
                        <>
                            <p>
                                Note: Studio71 is temporarily excluded from the rankers this month due to data
                                collection issues.
                            </p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 9 && (
                        <>
                            <p>Note: Studio71 experienced data collection issues through a portion of the period</p>
                        </>
                    )}
                    {period.year === 2022 && period.month === 11 && (
                        <>
                            <p>
                                Note: Samsung made an update to their Samsung Free app which caused a spike in
                                downloads. Samsung resolved the issue moving forward, starting in December. To mitigate
                                the issue, we have reprocessed our November data to remove those spikes.
                            </p>
                            <p>
                                Note: Sports Entertainment Network (SEN) experienced minor data collection issues
                                through a portion of the period.
                            </p>
                        </>
                    )}
                    {period.year === 2022 && period.month === 2 && (
                        <p>Note: Headgum experienced minor data collection issue through a portion of the period</p>
                    )}
                    {period.year === 2022 && period.month === 1 && (
                        <p>
                            Note: NZME/iHeartRadio experienced minor data collection issues through a portion of the
                            period.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 10 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify. This adjustment process will continue into next month as our team diligently works
                            to remove the Chartable prefix.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 11 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify.
                        </p>
                    )}
                    <PoweredByTriton />
                    <p>
                        <strong>ABOUT THE NEW ZEALAND PODCAST RANKER</strong>
                    </p>
                    <p>
                        The New Zealand Podcast Ranker lists the Top 100 podcasts from participating networks that are
                        consumed by listeners located in New Zealand.
                    </p>
                    <p>
                        The Top Network list ranks the top participating networks in New Zealand, consumed by listeners
                        located in New Zealand. Additional networks will join in future releases.
                    </p>
                    <p>
                        The New Zealand Podcast Ranker is based on a calendar month reporting period. The entities
                        listed are ranked by Monthly Listeners in accordance with the latest IAB Podcast Technical
                        Measurement Guidelines. If you wish to sort by downloads, you can click on the “Downloads”
                        column to re-sort amongst the existing data.
                    </p>
                    <p>
                        For podcasts with multiple categories in the RSS feed, we display and filter by only the first
                        category.
                    </p>
                    <p>
                        The Ranker includes the participation of the following networks and sales representation:{' '}
                        {newZealandNetworks
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((network, index) => (
                                <React.Fragment key={network.label}>
                                    <a href={network.url} target="_blank" rel="noreferrer">
                                        {network.label}
                                    </a>
                                    {index < newZealandNetworks.length - 1 && ', '}
                                </React.Fragment>
                            ))}
                        .
                    </p>
                    <p>
                        <strong>METRIC DEFINITIONS</strong>
                    </p>
                    <ul>
                        <li>
                            Listeners - A ‘listener’ is a single user who downloads content for either immediate or
                            delayed consumption. Listeners are identified by a combination of IP address and user agent
                            in accordance with the IAB filtering rules described in the IAB Podcast Measurement
                            Technical Guidelines v2.2.
                        </li>
                        <li>
                            Downloads - A ‘download’ is a unique file request that was downloaded. Downloads include
                            complete file downloads, progressive downloads, as well as partial downloads in accordance
                            with the IAB Podcast Measurement Technical Guidelines v2.2 filtering rules (i.e. spiders and
                            bots, data centers, one-minute of content minimum download, etc.). This process filters
                            multiple requests from the same IP address, user agent, episode and date.
                        </li>
                        <li>
                            Network - An entity that represents a group of podcasts that it either commissions, produces
                            itself or acts as the primary representative for that content in New Zealand.
                        </li>
                        <li>
                            Rank
                            <ul>
                                <li>
                                    <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                    &quot;Debut&quot; indicates that the show is new to the ranker, making its debut for
                                    the first time in the last 13 months.
                                </li>
                                <li>
                                    <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> &quot;Return&quot;
                                    indicates that the show has returned to the Top 100 after having not appeared in the
                                    previous reporting period(s).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            );

        case 'latam':
            return (
                <>
                    {period.year === 2023 && period.month === 12 && (
                        <>
                            <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 11 && (
                        <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                    )}
                    {period.year === 2022 && period.month === 11 && (
                        <p>
                            Note: Samsung made an update to their Samsung Free app which caused a spike in downloads.
                            Samsung resolved the issue moving forward, starting in December. To mitigate the issue, we
                            have reprocessed our November data to remove those spikes.
                        </p>
                    )}
                    {period.year === 2022 && period.month === 3 && (
                        <p>
                            Note: My Favorite Murder with Karen Kilgariff and Georgia Hardstark is being moved from SXM
                            Media to Wondery Brand Partnerships in April
                        </p>
                    )}
                    {period.year === 2024 && period.month === 10 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify. This adjustment process will continue into next month as our team diligently works
                            to remove the Chartable prefix.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 11 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify.
                        </p>
                    )}
                    <PoweredByTriton />
                    <p>
                        <strong>ABOUT THE LATAM SALES NETWORK AND PODCAST RANKERS</strong>
                    </p>
                    <p>
                        The LATAM Sales Network Ranker lists the Top Sales Networks in Latin America, as measured by
                        Podcast Metrics. Participating Networks include content owners/creators and sales representation
                        organizations. Networks have the option to have their downloads and listeners aggregated into
                        another entity to which they have specifically delegated advertising sales responsibilities,
                        such as a third-party sales house, sales representation firm, or sales network. In addition, the
                        Network may have its downloads and listeners reported separately.
                    </p>
                    <p>
                        The Latin America Podcast Ranker lists the Top 100 LATAM podcasts consumed by listeners who are
                        located in Latin America.
                    </p>
                    <p>
                        The LATAM Podcast Ranker is based on 4 or 5 week reporting periods (Monday to Sunday) that align
                        closely with a calendar month. The reporting calendar, using Average Weekly Download and User
                        metrics, improves trendability by minimizing the impact of the varying length of calendar months
                        and other factors that can impact media consumption. Participating entities that opt-in for
                        measurement are included. Downloads and Users are measured in accordance with the latest IAB
                        Podcast Technical Measurement Guidelines.
                    </p>
                    <p>
                        For podcasts with multiple categories in the RSS feed, we display and filter by only the first
                        category.
                    </p>
                    <p>
                        <strong>METRIC DEFINITIONS</strong>
                    </p>
                    <ul>
                        <li>
                            Downloads - A ‘download’ is a unique file request that was downloaded. Downloads include
                            complete file downloads, progressive downloads, as well as partial downloads in accordance
                            with the IAB Podcast Measurement Technical Guidelines v2.2 filtering rules (i.e. spiders and
                            bots, data centers, one-minute of content minimum download, etc.). This process filters
                            multiple requests from the same IP address, user agent, episode and date.
                        </li>
                        <li>
                            Publisher - An entity that represents a group of podcasts that it either commissions,
                            produces itself or acts as the primary representative for that content in Latin America.
                        </li>
                        <li>
                            Rank
                            <ul>
                                <li>
                                    <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                    &quot;Debut&quot; indicates that the show is new to the ranker, making its debut for
                                    the first time in the last 13 months.
                                </li>
                                <li>
                                    <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> &quot;Return&quot;
                                    indicates that the show has returned to the Top 100 after having not appeared in the
                                    previous reporting period(s).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            );

        case 'ca': {
            switch (locale) {
                case 'fr-CA':
                    return (
                        <>
                            {period.year === 2025 && period.month === 2 && (
                                <>
                                    <p>
                                        Remarque : Paramount a rencontré des problèmes de collecte de données ce mois-ci
                                        et est temporairement exclu des classements.
                                    </p>
                                </>
                            )}
                            {period.year === 2024 && period.month === 6 && (
                                <>
                                    <p>
                                        Remarque : Rooster Teeth a rencontré des problèmes de collecte de données au
                                        cours de cette période.
                                    </p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 12 && (
                                <>
                                    <p>Remarque: WarnerMedia est temporairement exclu des classements ce mois-ci.</p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 11 && (
                                <>
                                    <p>
                                        Remarque: NPR est temporairement exclu des classements ce mois-ci en raison
                                        d&apos;une migration prévue de la collecte de données. Ils reviendront dans les
                                        classements une fois la migration terminée.
                                    </p>
                                    <p>Remarque: WarnerMedia est temporairement exclu des classements ce mois-ci.</p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 10 && (
                                <>
                                    <p>
                                        Remarque: NPR est temporairement exclu des classements ce mois-ci en raison
                                        d&apos;une migration prévue de la collecte de données. Ils reviendront dans les
                                        classements une fois la migration terminée.
                                    </p>
                                    <p>
                                        Remarque: Studio71 est temporairement exclu des classements ce mois-ci en raison
                                        de problèmes de collecte de données.
                                    </p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 9 && (
                                <>
                                    <p>
                                        Remarque: NPR est temporairement exclu des classements ce mois-ci en raison
                                        d&apos;une migration prévue de la collecte de données. Ils reviendront dans les
                                        classements une fois la migration terminée.
                                    </p>
                                    <p>
                                        Remarque: Studio71 a rencontré des problèmes mineurs de collecte de données
                                        pendant une partie de la période
                                    </p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 5 && (
                                <p>
                                    Remarque: Paramount a rencontré des problèmes mineurs de collecte de données pendant
                                    une partie de la période
                                </p>
                            )}
                            {period.year === 2022 && period.month === 11 && (
                                <p>
                                    Remarque : Samsung a mis à jour son application Samsung Free, ce qui a provoqué une
                                    augmentation des téléchargements. Samsung a résolu le problème seulement à partir de
                                    décembre. Pour atténuer le problème dans le passé, nous avons recalculé nos
                                    métriques de novembre ce qui a pour effet de supprimer ces pics.
                                </p>
                            )}
                            {period.year === 2022 && period.month === 4 && (
                                <p>
                                    Remarque : Le podcast Something Was Wrong d&apos;audiochuck / Stitcher Media
                                    n&apos;est pas inclus dans le classement d&apos;avril en raison d&apos;un problème
                                    de collecte de données. Le podcast sera transféré sur Wondery Network durant le mois
                                    de mai et sera inclus dans le rapport du mois prochain.
                                </p>
                            )}
                            {period.year === 2022 && period.month === 3 && (
                                <p>
                                    Remarque : My Favorite Murder with Karen Kilgariff and Georgia Hardstark est
                                    transféré de SXM Media à Wondery Brand Partnerships en avril
                                </p>
                            )}
                            {period.year === 2024 && period.month === 9 && (
                                <p>
                                    Note : Le Weekly Show with Jon Stewart (Paramount) est temporairement exclu des
                                    rankers ce mois-ci pour cause de problèmes de collection des données durant cette
                                    période.
                                </p>
                            )}
                            {period.year === 2024 && period.month === 10 && (
                                <p>
                                    Remarque : Avec l’arrêt de Chartable et la suppression de son préfixe sur certains
                                    podcasts, nous avons ajusté les chiffres des podcasts pour éliminer les pics causés
                                    par les re-téléchargements sur Spotify. Ce processus d’ajustement se poursuivra le
                                    mois prochain, alors que notre équipe travaille assidûment à retirer le préfixe de
                                    Chartable.
                                </p>
                            )}
                            {period.year === 2024 && period.month === 11 && (
                                <p>
                                    Remarque : Avec l’arrêt de Chartable et la suppression de son préfixe sur certains
                                    podcasts, nous avons ajusté les chiffres des podcasts pour éliminer les pics causés
                                    par les re-téléchargements sur Spotify.
                                </p>
                            )}
                            <PoweredByTriton />
                            <p>
                                <strong>À PROPOS DES RÉSEAUX ET DES RAPPORTS DE PODCAST</strong>
                            </p>
                            <p>
                                Le rapport sur les réseaux canadiens répertorie les meilleurs réseaux des ventes au
                                Canada, tels que mesurés par Podcast Metrics. Les entités répertoriées sont classées en
                                fonction du nombre moyen de téléchargements hebdomadaires au cours de la période de
                                référence et situés au Canada.
                            </p>
                            <p>
                                Le Canadian Podcast Ranker répertorie les 100 meilleurs podcasts des éditeurs
                                participants qui sont consommés par les auditeurs situés au Canada. Le classement
                                All-Canadian Top 30 English Category Ranker ne répertorie que les 30 meilleurs podcasts
                                de langue anglaise commandés par un éditeur canadien, consommés par des auditeurs situés
                                au Canada. Le Top 30 Français Tout-Canadien répertorie uniquement les 30 meilleurs
                                podcasts de langue française commandés par un éditeur canadien, consommés par des
                                auditeurs situés au Canada.
                            </p>
                            <p>
                                Le Canadian Podcast Ranker est basé sur des périodes de rapport de 4 ou 5 semaines (du
                                lundi au dimanche) qui correspondent étroitement à un mois régulier. Le calendrier de
                                création de rapports, utilisant le téléchargement hebdomadaire moyen, améliore la
                                tendance en minimisant l&apos;impact de la durée variable des mois calendaires et
                                d&apos;autres facteurs pouvant avoir un impact sur la consommation de médias. Les
                                entités participantes qui optent pour la mesure sont incluses. Les téléchargements sont
                                mesurés selon les dernières directives techniques de mesure des podcasts de l&apos;IAB.
                            </p>
                            <p>
                                Pour les podcasts avec plusieurs catégories dans le flux RSS, nous affichons et filtrons
                                uniquement la première catégorie.
                            </p>
                            <p>
                                Le rapport inclut la participation des réseaux des vente suivants:{' '}
                                {canadaPublishers
                                    .sort((a, b) => a.label.localeCompare(b.label))
                                    .map((publisher, index) => (
                                        <React.Fragment key={publisher.label}>
                                            <a href={publisher.url} target="_blank" rel="noreferrer">
                                                {publisher.label}
                                            </a>
                                            {index < canadaPublishers.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                .
                            </p>
                            <p>
                                <strong>DÉFINITIONS DES MÉTRIQUES</strong>
                            </p>
                            <ul>
                                <li>
                                    Downlaods (Téléchargements) - Un « téléchargement » est une demande de fichier
                                    unique qui a été téléchargée. Les téléchargements comprennent les téléchargements de
                                    fichiers complets, les téléchargements progressifs, ainsi que les téléchargements
                                    partiels conformément aux règles de filtrage des directives techniques de mesure des
                                    podcasts de l&apos;IAB v2.2 (c&apos;est-à-dire les spiders et les bots, les centres
                                    de données, une minute de téléchargement minimum de contenu, etc.). Ce processus
                                    filtre plusieurs requêtes provenant des mêmes adresse IP, agent utilisateur, épisode
                                    et date.
                                </li>
                                <li>
                                    Nombre de nouveaux épisodes - Le nombre de nouveaux épisodes publiés au cours de la
                                    période de rapport
                                </li>
                                <li>
                                    Rang
                                    <ul>
                                        <li>
                                            <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                            &quot;Debut&quot; indique que l&apos;émission est nouvelle dans le rapport,
                                            faisant ses débuts pour la première fois au cours des 13 derniers mois.
                                        </li>
                                        <li>
                                            <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                            &quot;Retour&quot; indique que l&apos;émission est revenue dans le Top 100
                                            après n&apos;avoir pas paru au cours de la ou des périodes de rapport
                                            précédentes.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </>
                    );

                default:
                    return (
                        <>
                            {period.year === 2025 && period.month === 2 && (
                                <>
                                    <p>
                                        Note: Paramount experienced data collection issues this month and is temporarily
                                        excluded from the rankers.
                                    </p>
                                </>
                            )}
                            {period.year === 2024 && period.month === 6 && (
                                <>
                                    <p>Note: Rooster Teeth experienced data collection issues this period.</p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 12 && (
                                <>
                                    <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 11 && (
                                <>
                                    <p>
                                        Note: NPR is temporarily excluded from the rankers this month due to a planned
                                        data collection migration. They will return to the rankers once the migration is
                                        complete.
                                    </p>
                                    <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 10 && (
                                <>
                                    <p>
                                        Note: NPR is temporarily excluded from the rankers this month due to a planned
                                        data collection migration. They will return to the rankers once the migration is
                                        complete.
                                    </p>
                                    <p>
                                        Note: Studio71 is temporarily excluded from the rankers this month due to data
                                        collection issues.
                                    </p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 9 && (
                                <>
                                    <p>
                                        Note: NPR is temporarily excluded from the rankers this month due to a planned
                                        data collection migration. They will return to the rankers once the migration is
                                        complete.
                                    </p>
                                    <p>
                                        Note: Studio71 experienced data collection issues through a portion of the
                                        period
                                    </p>
                                </>
                            )}
                            {period.year === 2023 && period.month === 5 && (
                                <p>
                                    Note: Paramount experienced minor data collection issues through a portion of the
                                    period.
                                </p>
                            )}
                            {period.year === 2022 && period.month === 11 && (
                                <p>
                                    Note: Samsung made an update to their Samsung Free app which caused a spike in
                                    downloads. Samsung resolved the issue moving forward, starting in December. To
                                    mitigate the issue, we have reprocessed our November data to remove those spikes.
                                </p>
                            )}
                            {period.year === 2022 && period.month === 4 && (
                                <p>
                                    Note: The podcast Something Was Wrong from audiochuck / Stitcher Media is not
                                    included in the April ranker because of a data collection issue. The podcast is
                                    moving to Wondery Network in May and will be included in next month’s ranker.
                                </p>
                            )}
                            {period.year === 2022 && period.month === 3 && (
                                <p>
                                    Note: My Favorite Murder with Karen Kilgariff and Georgia Hardstark is being moved
                                    from SXM Media to Wondery Brand Partnerships in April
                                </p>
                            )}
                            {period.year === 2024 && period.month === 9 && (
                                <p>
                                    Note: The Weekly Show with Jon Stewart (Paramount) is temporarily excluded from the
                                    rankers this month due to data collection issues this period.
                                </p>
                            )}
                            {period.year === 2024 && period.month === 10 && (
                                <p>
                                    Note: With the sunsetting of Chartable and the removal of its prefix from certain
                                    podcasts, we have adjusted the podcast numbers to eliminate the spikes caused by
                                    re-downloads on Spotify. This adjustment process will continue into next month as
                                    our team diligently works to remove the Chartable prefix.
                                </p>
                            )}
                            {period.year === 2024 && period.month === 11 && (
                                <p>
                                    Note: With the sunsetting of Chartable and the removal of its prefix from certain
                                    podcasts, we have adjusted the podcast numbers to eliminate the spikes caused by
                                    re-downloads on Spotify.
                                </p>
                            )}
                            <PoweredByTriton />
                            <p>
                                <strong>ABOUT THE CANADIAN SALES NETWORK AND PODCAST RANKERS </strong>
                            </p>
                            <p>
                                The Canadian Sales Network Ranker lists the Top Sales Networks in Canada, as measured by
                                Podcast Metrics. Participating Networks include content owners/creators and sales
                                representation organizations. Networks have the option to have their downloads and
                                listeners aggregated into another entity to which they have specifically delegated
                                advertising sales responsibilities, such as a third-party sales house, sales
                                representation firm, or sales network. In addition, the Network may have its downloads
                                and listeners reported separately.
                            </p>
                            <p>
                                The Canadian Podcast Ranker lists the Top 100 podcasts from participating publishers
                                that are consumed by listeners located in Canada. The All-Canadian Top 30 English
                                Category Ranker lists only the Top 30 English language podcasts commissioned by a
                                Canadian publisher, consumed by listeners located in Canada. The All-Canadian Top 30
                                French Category Ranker lists only the Top 30 French language podcasts commissioned by a
                                Canadian publisher, consumed by listeners located in Canada.
                            </p>
                            <p>
                                The Canadian Podcast Ranker is based on 4 or 5 week reporting periods (Monday to Sunday)
                                that align closely with a calendar month. The reporting calendar, using Average Weekly
                                Download, improves trendability by minimizing the impact of the varying length of
                                calendar months and other factors that can impact media consumption. Participating
                                entities that opt-in for measurement are included. Downloads are measured under the
                                latest IAB Podcast Technical Measurement Guidelines.
                            </p>
                            <p>
                                For podcasts with multiple categories in the RSS feed, we display and filter by only the
                                first category.
                            </p>
                            <p>
                                The Ranker includes the participation of the following Sales Networks:{' '}
                                {canadaPublishers
                                    .sort((a, b) => a.label.localeCompare(b.label))
                                    .map((publisher, index) => (
                                        <React.Fragment key={publisher.label}>
                                            <a href={publisher.url} target="_blank" rel="noreferrer">
                                                {publisher.label}
                                            </a>
                                            {index < canadaPublishers.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                .
                            </p>
                            <p>
                                <strong>METRIC DEFINITIONS</strong>
                            </p>
                            <ul>
                                <li>
                                    Downloads - A ‘download’ is a unique file request that was downloaded. Downloads
                                    include complete file downloads, progressive downloads, as well as partial downloads
                                    in accordance with the IAB Podcast Measurement Technical Guidelines v2.2 filtering
                                    rules (i.e. spiders and bots, data centers, one-minute of content minimum download,
                                    etc.). This process filters multiple requests from the same IP address, user agent,
                                    episode and date.
                                </li>
                                <li>
                                    Number of New Episodes - The number of new episodes released during the reporting
                                    period
                                </li>
                                <li>
                                    Rank
                                    <ul>
                                        <li>
                                            <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                            &quot;Debut&quot; indicates that the show is new to the ranker, making its
                                            debut for the first time in the last 13 months.
                                        </li>
                                        <li>
                                            <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                            &quot;Return&quot; indicates that the show has returned to the Top 100 after
                                            having not appeared in the previous reporting period(s).
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </>
                    );
            }
        }

        case 'us':
            return (
                <>
                    {period.year === 2025 && period.month === 2 && (
                        <>
                            <p>
                                Note: Paramount experienced data collection issues this month and is temporarily
                                excluded from the rankers.
                            </p>
                        </>
                    )}
                    {period.year === 2024 && period.month === 6 && (
                        <>
                            <p>Note: Rooster Teeth experienced data collection issues this period.</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 12 && (
                        <>
                            <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 11 && (
                        <>
                            <p>
                                Note: NPR is temporarily excluded from the rankers this month due to a planned data
                                collection migration. They will return to the rankers once the migration is complete.
                            </p>
                            <p>Note: WarnerMedia is temporarily excluded from the rankers this month.</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 10 && (
                        <>
                            <p>
                                Note: NPR is temporarily excluded from the rankers this month due to a planned data
                                collection migration. They will return to the rankers once the migration is complete.
                            </p>
                            <p>
                                Note: Cumulus experienced data collection issues with the Daily Wire programs for this
                                period.
                            </p>
                            <p>
                                Note: Studio71 is temporarily excluded from the rankers this month due to data
                                collection issues.
                            </p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 9 && (
                        <>
                            <p>
                                Note: NPR is temporarily excluded from the rankers this month due to a planned data
                                collection migration. They will return to the rankers once the migration is complete.
                            </p>
                            <p>
                                Note: Cumulus experienced data collection issues with the Daily Wire programs for this
                                period.
                            </p>
                            <p>Note: Studio71 experienced data collection issues through a portion of the period.</p>
                        </>
                    )}
                    {period.year === 2023 && (period.month === 8 || period.month === 7) && (
                        <p>
                            Note: Cumulus experienced data collection issues with the Daily Wire programs for this
                            period.
                        </p>
                    )}
                    {period.year === 2023 && period.month === 5 && (
                        <p>Note: Paramount experienced minor data collection issues through a portion of the period.</p>
                    )}
                    {period.year === 2022 && period.month === 11 && (
                        <p>
                            Note: Samsung made an update to their Samsung Free app which caused a spike in downloads.
                            Samsung resolved the issue moving forward, starting in December. To mitigate the issue, we
                            have reprocessed our November data to remove those spikes.
                        </p>
                    )}
                    {period.year === 2022 && period.month === 8 && (
                        <p>
                            *Audacy Podcast Network became the sales representation for CBS Sports starting with August
                            data, which is now part of Audacy Podcast Network numbers, in addition to Paramount numbers.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 9 && (
                        <p>
                            Note: The Weekly Show with Jon Stewart (Paramount) is temporarily excluded from the rankers
                            this month due to data collection issues this period.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 10 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify. This adjustment process will continue into next month as our team diligently works
                            to remove the Chartable prefix.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 11 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify.
                        </p>
                    )}
                    <PoweredByTriton />
                    <p>
                        <strong>ABOUT THE U.S. SALES NETWORK AND PODCAST RANKERS </strong>
                    </p>
                    <p>
                        Participating Networks include content owners/creators and sales representation organizations.
                        Networks have the option to have their downloads and listeners aggregated into another entity to
                        which they have specifically delegated advertising sales responsibilities, such as a third-party
                        sales house, sales representation firm, or sales network. In addition, the Network may have its
                        downloads and listeners reported separately.
                    </p>
                    <p>
                        The U.S. Sales Network Ranker lists the Top 20 Sales Networks in the U.S., as measured by
                        Podcast Metrics. The entities listed are ranked by Average Weekly Downloads and Average Weekly
                        Users in the reporting period who are located in the United States.
                    </p>
                    <p>
                        The U.S. Podcast Ranker lists the Top 150 Podcasts consumed by users who are located in the
                        United States. The entities listed are ranked by Average Weekly Downloads and Average Weekly
                        Users.
                    </p>
                    <p>
                        The U.S. Podcast Ranker is based on 4 or 5 week reporting periods (Monday to Sunday) that align
                        closely with a calendar month. The reporting calendar, using Average Weekly Download and User
                        metrics, improves trendability by minimizing the impact of the varying length of calendar months
                        and other factors that can impact media consumption. Participating entities that opt-in for
                        measurement are included. Downloads and Users are measured in accordance with the latest IAB
                        Podcast Technical Measurement Guidelines.
                    </p>
                    <p>
                        <strong>METRIC AND DEFINITIONS</strong>
                    </p>
                    <ul>
                        <li>
                            Downloads - A ‘download’ is a unique file request that was downloaded. Downloads include
                            complete file downloads, progressive downloads, as well as partial downloads in accordance
                            with the IAB Podcast Measurement Technical Guidelines v2.2 filtering rules (i.e. spiders and
                            bots, data centers, one-minute of content minimum download, etc.). This process filters
                            multiple requests from the same IP address, user agent, episode and date.
                        </li>
                        <li>
                            Users - A user is an individual who downloads content for either immediate or delayed
                            consumption. Users are identified by a combination of IP address and user agent in
                            accordance with the IAB filtering rules described in the IAB Podcast Measurement Technical
                            Guidelines v2.2. Note that Users do not necessarily listen to all downloaded content. For
                            this reason, we have chosen to use the term “User” instead of “Listener” in this context to
                            avoid confusion.
                        </li>
                        <li>
                            Number of Active Podcasts - A count of all podcast titles which had at least one qualified
                            download during the reporting period
                        </li>
                        <li>
                            Number of New Episodes - The number of new episodes released during the reporting period
                        </li>
                        <li>
                            Rank
                            <ul>
                                <li>
                                    <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                    &quot;Debut&quot; indicates that the show is new to the ranker, making its debut for
                                    the first time in the last 13 months.
                                </li>
                                <li>
                                    <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> &quot;Return&quot;
                                    indicates that the show has returned to the Top 150 after having not appeared in the
                                    previous reporting period(s).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            );

        case 'nl':
            return (
                <>
                    {period.year === 2023 && period.month === 10 && (
                        <>
                            <p>
                                Note: Studio71 is temporarily excluded from the rankers this month due to data
                                collection issues.
                            </p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 9 && (
                        <>
                            <p>Note: Studio71 experienced data collection issues through a portion of the period</p>
                        </>
                    )}
                    {period.year === 2023 && period.month === 6 && (
                        <p>Note: FC Afkicken experienced data collection issues through a portion of the period.</p>
                    )}
                    {period.year === 2022 && period.month === 11 && (
                        <p>
                            Note: Samsung made an update to their Samsung Free app which caused a spike in downloads.
                            Samsung resolved the issue moving forward, starting in December. To mitigate the issue, we
                            have reprocessed our November data to remove those spikes.
                        </p>
                    )}
                    {period.year === 2022 && period.month === 3 && (
                        <p>
                            Note: My Favorite Murder with Karen Kilgariff and Georgia Hardstark is being moved from SXM
                            Media to Wondery Brand Partnerships in April
                        </p>
                    )}
                    {period.year === 2024 && period.month === 10 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify. This adjustment process will continue into next month as our team diligently works
                            to remove the Chartable prefix.
                        </p>
                    )}
                    {period.year === 2024 && period.month === 11 && (
                        <p>
                            Note: With the sunsetting of Chartable and the removal of its prefix from certain podcasts,
                            we have adjusted the podcast numbers to eliminate the spikes caused by re-downloads on
                            Spotify.
                        </p>
                    )}
                    <PoweredByTriton />
                    <p>
                        <strong>ABOUT THE NETHERLANDS PODCAST RANKER</strong>
                    </p>
                    <p>
                        The Netherlands Podcast Ranker lists the Top 100 podcasts consumed by listeners who are located
                        in the Netherlands.
                    </p>
                    <p>
                        The Netherlands Podcast Ranker is based on 4 or 5 week reporting periods (Monday to Sunday) that
                        align closely with a calendar month. The reporting calendar, using Average Weekly Download and
                        User metrics, improves trendability by minimizing the impact of the varying length of calendar
                        months and other factors that can impact media consumption. Participating entities that opt-in
                        for measurement are included. Downloads and Users are measured in accordance with the latest IAB
                        Podcast Technical Measurement Guidelines.
                    </p>
                    <p>
                        For podcasts with multiple categories in the RSS feed, we display and filter by only the first
                        category.
                    </p>
                    <p>
                        <strong>METRIC DEFINITIONS</strong>
                    </p>
                    <ul>
                        <li>
                            Downloads - A ‘download’ is a unique file request that was downloaded. Downloads include
                            complete file downloads, progressive downloads, as well as partial downloads in accordance
                            with the IAB Podcast Measurement Technical Guidelines v2.2 filtering rules (i.e. spiders and
                            bots, data centers, one-minute of content minimum download, etc.). This process filters
                            multiple requests from the same IP address, user agent, episode and date.
                        </li>
                        <li>
                            Publisher - An entity that represents a group of podcasts that it either commissions,
                            produces itself or acts as the primary representative for that content in the Netherlands.
                        </li>
                        <li>
                            Rank
                            <ul>
                                <li>
                                    <StarOutlineIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />{' '}
                                    &quot;Debut&quot; indicates that the show is new to the ranker, making its debut for
                                    the first time in the last 13 months.
                                </li>
                                <li>
                                    <ReplayIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> &quot;Return&quot;
                                    indicates that the show has returned to the Top 100 after having not appeared in the
                                    previous reporting period(s).
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            );
    }
};

const australianPublishers: { label: string; url: string }[] = [
    { label: 'Acast', url: 'https://www.acast.com/' },
    { label: 'Australian Broadcasting Corporation', url: 'https://www.abc.net.au/listen/podcasts' },
    { label: 'Adore Beauty', url: 'https://www.adorebeauty.com.au/podcast.html' },
    { label: 'ARN/iHeartPodcast Network Australia', url: 'https://arn.com.au/advertise-with-us/' },
    { label: 'Audioboom', url: 'https://audioboom.com/' },
    { label: 'Australian Birth Stories', url: 'https://australianbirthstories.com/contact/' },
    { label: 'BBC', url: 'https://arn.com.au/advertise-with-us/' },
    { label: 'Bloke in a Bar', url: 'https://www.bloke.com.au/' },
    { label: 'DM Podcasts', url: 'https://diamantina.com.au/' },
    { label: 'Hidden Brain Media', url: 'https://hiddenbrain.org/' },
    { label: 'HODD Media', url: 'https://howotherdadsdad.com/' },
    { label: 'Johns Media', url: 'https://johnsmedia.com.au/' },
    { label: 'Kinderling Kids Radio', url: 'https://www.kinderlingkids.com/' },
    { label: 'LiSTNR (SCA)', url: 'https://www.southerncrossaustereo.com.au/listnr/' },
    { label: 'Motorsport Podcast Network', url: 'https://www.v8sleuth.com.au/motorsport-podcast-network-launched/' },
    { label: 'NBCUniversal News Group', url: 'https://www.nbcnews.com/podcasts' },
    { label: 'News Corp Australia', url: 'https://www.newscorpaustralia.com/' },
    { label: 'Nine', url: 'https://www.nineforbrands.com.au/' },
    { label: 'Nova Entertainment', url: 'https://www.novaentertainment.com.au/' },
    { label: 'Podshape', url: 'https://podshape.com/' },
    { label: 'Ranieri & Co', url: 'https://www.ranieriandco.com/' },
    { label: 'RSN Racing & Sport', url: 'https://www.rsn.net.au/contact-us' },
    { label: 'Schwartz Media', url: 'https://schwartzmedia.com.au/' },
    { label: 'Sports Entertainment Network (SEN)', url: 'https://www.sportsentertainmentnetwork.com.au/' },
    { label: 'SiriusXM Podcast Network', url: 'https://www.stitcher.com/' },
    { label: 'TOFOP Productions', url: 'https://tofop.com/' },
    { label: 'Guardian Media Group', url: 'https://www.theguardian.com/' },
    { label: 'The Chaser', url: 'https://chaser.com.au/' },
    { label: 'The Horde', url: 'https://thehorde.com/' },
    { label: 'Wavelength Creative', url: 'https://wavelengthcreative.com/' },
    { label: 'West Australian Newspapers', url: 'https://thewest.com.au/' },
    { label: 'Wondery', url: 'https://wondery.com/' },
];

const newZealandNetworks: { label: string; url: string }[] = [
    { label: 'Audioboom', url: 'https://audioboom.com/' },
    { label: 'LiSTNR (SCA)', url: 'https://www.southerncrossaustereo.com.au/listnr/' },
    { label: 'NBCUniversal News Group', url: 'https://www.nbcnews.com/podcasts' },
    { label: 'Nova Entertainment', url: 'https://www.novaentertainment.com.au/' },
    { label: 'NZME/iHeartRadio/ARN', url: 'https://www.nzme.co.nz/nzme-brands/nzme-radio/' },
    { label: 'rova (MediaWorks)', url: 'https://www.mediaworks.co.nz/home/our-brands.html' },
    { label: 'SiriusXM Podcast Network', url: 'https://www.stitcher.com/' },
    { label: 'Brodie Kane Media', url: 'https://www.brodiekanemedia.com/podcasts/' },
];

const canadaPublishers: { label: string; url: string }[] = [
    { label: 'SiriusXM Podcast Network', url: 'https://www.stitcher.com/' },
    { label: 'Audioboom', url: 'https://audioboom.com/' },
    { label: 'Wondery', url: 'https://wondery.com/' },
    { label: 'NBCUniversal News', url: 'https://www.nbcuniversal.com/newsroom' },
    { label: 'CBC / Radio-Canada', url: 'https://www.cbc.ca/radio/podcasts' },
    { label: 'Paramount', url: 'https://www.paramount.com/' },
    { label: 'Salem Podcast Network', url: 'https://salemmedia.com/salem-podcast-network/' },
    { label: 'NPR', url: 'https://www.npr.org/podcasts-and-shows/' },
    { label: 'The Roost Podcast Network', url: 'https://roosterteeth.com/' },
    { label: 'All Things Comedy', url: 'https://allthingscomedy.com/podcasts' },
    { label: 'Québecor', url: 'https://www.qub.ca/radio#/' },
    { label: 'Cogeco Media', url: 'https://www.cogecomedia.com/' },
    { label: 'Cloud10', url: 'https://cloud10.fm/' },
    { label: 'TPX Podcast Network', url: 'https://www.thepodcastexchange.ca/production-consulting' },
    { label: 'Soundrise', url: 'https://www.wearesoundrise.com/shows' },
    { label: 'Cumulus Podcast Network', url: 'https://cumuluspodcastnetwork.com/' },
    { label: 'Focus On the Family', url: 'https://podcasts.focusonthefamily.com/' },
    { label: 'Beasley Media Group', url: 'https://bbgi.com/brands/' },
];

const PoweredByTriton = (): React.ReactElement => {
    return (
        <>
            <p>
                <strong>
                    <FormattedMessage
                        defaultMessage="POWERED BY TRITON DIGITAL'S PODCAST METRICS"
                        description="Ranker disclaimer powered by Triton Digital heading"
                    />
                </strong>
            </p>
            <p>
                <FormattedMessage
                    defaultMessage=" Triton Digital's Podcast Metrics measurement service is certified by the IAB Tech Lab as complying
                        with Version 2.2 of the IAB Podcast Measurement Technical Guidelines."
                    description="Ranker disclaimer powered by Triton Digital body"
                />
            </p>
        </>
    );
};
